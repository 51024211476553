import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import TextGroup from '../components/TextGroup'
import ErpComp from '../components/ErpComp'


const NestingPage = () => (
    <Layout>
        <SEO title="Nesting Software" keywords={[`Nesting`, `Production Environment`, `react`]} />
        <div className="BlueGradient">
        <div className="--headerClear"></div>
            <section className="--sectionPaceM --centerMargin --maxwidthS --edgePadding --centerTxt --heroAnimation ">
                <h2><span>AccuCAM & AccuFAB</span> optimize your laser production</h2>
                <p>Ncell Nesting Solutions offer a cohesive production environment that reduces production time, improves material utilization and streamlines processes.</p>
            </section>
        </div>
        <section className="--centerMargin --maxwidthL">
      <article id="--productOverview">
        <h5>AccuFAB Overview</h5>
        <p className="DarkP">From stand-alone lasers to multi-laser FMS systems, AccuFAB increases the reliability and efficiency of your laser cutting systems. AccuFAB is more than a dynamic nesting system. Getting the right part at the right time is key to your shops profitability. AccuFAB ensures you have what you need when you need it the most. Feed production data in real time back to your ERP system for accurate part costing.</p>
      </article>
    </section>
    <section className="--centerMargin --maxwidthL --grid6040 --edgePadding --sectionPace --alignCenter">
      <article>
        <h2 className="DarkH">Information where you want it</h2>
        <p className="DarkP">AccuFAB puts the right information in the right place to guarantee fast and easy part processing. Whether etched directly on a part, printed or displayed on shop floor monitors, AccuFAB helps shop floor personnel process parts quickly and accurately.</p>
      </article>
      <div id="stackedParts" className="--backgroundContain"></div>
    </section>
    <ErpComp />
      <div className="--sectionPace">
    <section className="--centerMargin --maxwidthL --edgePadding">
      <artcle>
        <h2 className="DarkH">Speed meets Superior Standards</h2>
        <p className="DarkP">Our SmartPart technology makes critical programming and nesting decisions so you don’t have to. Without the need for extra time or manpower, smarter programming means more productivity where it matters: on the shop floor.</p>
      </artcle>
    </section>
    
    <section className="--centerMargin --maxwidthL --edgePadding --grid5050">
    <div className="no_bullet">
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Intelligent high speed cutting</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Comprehensive Cutting Conditions</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Fiber Laser Optimized</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Intelligent Slug Destruct</p>

            </div>   
      <div className="no_bullet">
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Smartpart® Cutting Technology</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Automatic Micro-Tabbing</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Smart Grain Restrictions</p>
            </div>    
        </section>
        </div>
        <section className="--maxwidthL --edgePadding --sectionPace --grid --centerMargin ">
        <article className="--maxwidthL --centerMargin --centerTxt">
          <h2 className="DarkH">It Makes Sense</h2>
          <p className="DarkP --maxwidthM">Taking advantage of the high speed cutting features on your laser, AccuFAB maximizes laser throughput and guarantees part quality without the need to program manually. </p>
          <div className="--headerClear"></div>
        </article>
        <section className="--grid5050">
        <article>
        <div className="--paragraphImg" id="commonSenseImg"></div>
        <h5>CommonSense</h5>
        <p className="DarkP">More than just common line cutting, CommonSense determines when and if common line cutting is beneficial on a case by case basis and nests parts for optimal results.  Because it is fully automatic, CommonSense decreases your production costs without adding additional manpower or time. </p>
      </article>
      <article>
        <div className="--paragraphImg" id="kitSenseImg"></div>
        <h5>KitSense</h5>
        <p className="DarkP">Manage complex assemblies through your lasers with KitSense. By automatically grouping assembly parts into kits, KitSense guarantees all parts for an assembly are cut together, giving you more finished products in less time. Advanced tools like automatic back fill and flexible order quantities guarantee you get the assemblies you need and the material utilization you demand. 
</p>
      </article>
      </section>
      </section>
      <div className="--headerClear"></div>
      <div className="--headerClear"></div>
      <section className="--centerMargin --maxwidthL">
      <article id="--productOverview">
        <h5>AccuCAM Overview</h5>
        <p className="DarkP">The easy-to-use system is powered by an industry leading nesting engine that guarantees improved material utilization and optimized laser processing time.From ERP to cut parts, AccuCAM gives you control over your laser processing.  Efficient nested sheets optimized for your lasers are automatically generated along with comprehensive shop floor documents that help guarantee parts are processed quickly and efficiently. Leave nothing to chance with AccuCAM.</p>
      </article>
    </section>
    <div className="--sectionPace">
    <section className="--centerMargin --maxwidthL --edgePadding">
      <artcle>
        <h2 className="DarkH">AccuCAM is designed for maximum productivity</h2>
        <p className="DarkP">Take nesting to a new level with a long list of features designed to do more in less time. </p>
      </artcle>
    </section>
    <section className="--centerMargin --maxwidthL --edgePadding --grid5050">
    <div className="no_bullet">
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Multi-mode True Shape Nesting</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Comprehensive Cutting Conditions</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Intelligent Micro-Tabbing</p>

            </div>   
      <div className="no_bullet">
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Automatic Part-in-Part Nesting</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Smart Grain Control</p>
                <img alt="Checkmark Icon" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Part Prioritization</p>
            </div>    
        </section>
        </div>
        <section className="--centerMargin --maxwidthL --grid6040 --edgePadding --sectionPace --alignCenter">
      <article>
        <h2 className="DarkH">Automation Options</h2>
        <p className="DarkP">Connect with AccuFAB for seamless ERP integration, Part production management, Remote access, and more.</p>
      </article>
      <div id="accuCamImg" className="--backgroundContain"></div>
    </section>
        <section className="--maxwidthL --edgePadding --sectionPace --centerMargin">
      <article className="--maxwidthS --centerMargin --centerTxt">
          <h2 className="DarkH">Would you like a demo?</h2>
          <p className="DarkP">Let us walk you through this software and answer your questions in a one-on-one webinar.</p>
        </article>
        <div className="--headerClear"></div>
        <section className="--maxwidthM --grid5050 --centerMargin --alignCenter">
          <a href="tel:9527465125" className="--bkgContainer --alignCenter --gridGapS" id="contactLinks">
            <div id="phoneIcon"></div>
            <article className="--grid">
              <span className="--flex --alignCenter --gridGapS"><h5 className="DarkH">Call Us</h5><p class="DarkP --margin0">7am–5pm CST</p></span>
              <p class="DarkP --margin0">(952) 746-5125</p>
            </article>
          </a>
          <a href="mailto:info@ncell.com" className="--bkgContainer --justifyLeft --gridGapS" id="contactLinks">
            <div id="emailIcon"></div>
            <article>
              <h5 className="DarkH">Email Us</h5>
              <p class="DarkP --margin0">info@ncell.com</p>
            </article>
          </a>
          </section>
          </section>
    </Layout>
)

export default NestingPage